<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <v-col cols="4">
            <company-select
              @chosen="setCompany"
              v-model="selectedCompany"
              cols="6"
            ></company-select>
          </v-col>

          <v-col cols="4">
            <v-select
              v-if="selectedCompany"
              menu-props="auto"
              v-model="selectedCarrier"
              :items="carriersWithServices"
              item-text="name"
              return-object
              label="Select Carrier"
              outlined
              dense
              @change="setCarrierServices"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-if="selectedCarrier && selectedCarrier.id && carrierServices"
              menu-props="auto"
              v-model="selectedCarrierService"
              :items="carrierServices"
              item-text="service"
              return-object
              label="Select Carrier Service"
              outlined
              dense
              @change="getMargins"
            >
            </v-select>
          </v-col>
          <v-col cols="3" v-if="selectedCarrierService">
            <v-btn outlined @click="clearSelectedService"
              >load margins for all {{ selectedCarrier.name }} services</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="selectedCompany && margins">
          <!-- current margins -->
          <v-col cols="12">
            <v-card outlined class="pa-2">
              <v-card-title
                >Current Markup For {{ currentMarginsHeader }} <v-spacer />
                <ExportButtons
                  v-if="margins && margins.length"
                  :headers="marginsHeaders"
                  :data="marginsExport"
                  :title="'margins_' + selectedCompany.name"
                />
              </v-card-title>
              <div class="pa-2" v-if="margins && margins.length">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-data-table
                :headers="marginsHeaders"
                :items="margins"
                :items-per-page="5"
                :search="search"
                v-if="margins.length"
              >
                <template v-slot:item.country_code="{ item }">
                  {{ getCountryName(item.country_code) }}
                </template>
                <template v-slot:item.margin="{ item }">
                  <v-text-field
                    v-if="updating && updateForm.id === item.id"
                    type="number"
                    v-model="item.margin"
                    label="markup"
                    dense
                    outlined
                    step="1"
                    suffix="%"
                  ></v-text-field>
                  <div v-else>
                    {{
                      item.fixedRateActive &&
                      !item.use_bigger_val_margin_or_fixed
                        ? ""
                        : formatPercentage(item.margin)
                    }}
                  </div>
                </template>

                <template v-slot:item.fixedRateActive="{ item }">
                  <v-checkbox
                    v-if="updating && updateForm.id === item.id"
                    class="mt-0"
                    v-model="item.fixedRateActive"
                    label=""
                  >
                  </v-checkbox>
                  <div v-else>
                    {{ formatBoolean(item.fixedRateActive) }}
                  </div>
                </template>

                <template v-slot:item.fixedRate="{ item }">
                  <v-text-field
                    v-if="
                      updating &&
                      updateForm.id === item.id &&
                      item.fixedRateActive
                    "
                    type="number"
                    v-model="item.fixedRate"
                    label="fixed rate"
                    dense
                    outlined
                    step="0.01"
                    prefix="£"
                  ></v-text-field>
                  <div v-else>
                    {{ item.fixedRate ? formatCurrency(item.fixedRate) : "" }}
                  </div>
                </template>

                <template v-slot:item.use_bigger_val_margin_or_fixed="{ item }">
                  <v-checkbox
                    v-if="updating && updateForm.id === item.id"
                    class="mt-0"
                    v-model="item.use_bigger_val_margin_or_fixed"
                    label=""
                  >
                  </v-checkbox>
                  <div v-else>
                    {{ formatBoolean(item.use_bigger_val_margin_or_fixed) }}
                  </div>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-btn
                    v-if="updating && updateForm.id === item.id"
                    small
                    @click="resetUpdatingMargin(item)"
                    color="red"
                    outlined
                  >
                    <b>X</b>
                  </v-btn>
                  <v-btn
                    v-if="updating && updateForm.id === item.id"
                    small
                    @click="updateMargin(item)"
                    color="primary"
                    outlined
                  >
                    <v-icon> check </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!updating"
                    small
                    @click="toggleUpdatingMargin(item)"
                    color="primary"
                    outlined
                  >
                    <v-icon> edit </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!updating"
                    small
                    @click="deleteMargin(item)"
                    color="red"
                    outlined
                  >
                    <v-icon> delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <div v-else class="ma-4">
                This
                {{
                  selectedCarrier
                    ? selectedCarrierService
                      ? "service"
                      : "carrier"
                    : "company"
                }}
                has no markup yet
              </div>
            </v-card>
          </v-col>

          <!-- create/update global margin -->
          <v-col cols="12" v-if="selectedCarrier && !selectedCarrierService">
            <v-card outlined class="pa-2" :loading="loading">
              <v-form
                @submit.prevent
                class="ma-3"
                ref="form"
                v-model="validForm"
                lazy-validation
              >
                <v-card-title
                  >{{ currentGlobalCarrierMargin ? "Update" : "Create" }} Global
                  markup For {{ selectedCarrier.name }}
                </v-card-title>
                <v-card-subtitle>
                  This markup gets applied when there's no service markup that
                  can be matched to an order
                </v-card-subtitle>
                <v-row class="ma-2">
                  <v-col cols="3">
                    <v-checkbox
                      class="mt-0"
                      v-model="globalMarginForm.fixedRateActive"
                      label="fixed rate active"
                      @change="validateForm()"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-if="globalMarginForm.fixedRateActive"
                      class="mt-0"
                      v-model="globalMarginForm.use_bigger_val_margin_or_fixed"
                      label="highest between margin and fixed"
                      @change="validateForm()"
                    >
                    </v-checkbox>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      v-show="globalMarginForm.fixedRateActive"
                      type="number"
                      v-model="globalMarginForm.fixedRate"
                      label="fixed rate"
                      dense
                      outlined
                      step="0.01"
                      prefix="£"
                      :rules="[fixedRateRule]"
                      @change="validateForm()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      v-show="
                        !globalMarginForm.fixedRateActive ||
                        globalMarginForm.use_bigger_val_margin_or_fixed
                      "
                      type="number"
                      v-model="globalMarginForm.margin"
                      label="markup"
                      dense
                      outlined
                      step="1"
                      suffix="%"
                      :rules="[marginRule]"
                      @change="validateForm()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" align="center">
                    <v-btn
                      :disabled="!validForm"
                      color="primary"
                      outlined
                      @click="insertMargin(true)"
                      >Save Global Markup
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>

          <!-- insert new margin -->
          <v-col cols="12" v-if="selectedCarrier && selectedCarrierService">
            <v-card outlined class="pa-2" :loading="loading">
              <v-form
                @submit.prevent
                class="ma-3"
                ref="form"
                v-model="validForm"
                lazy-validation
              >
                <v-card-title
                  >Create Markup For {{ selectedCarrierService.service }}
                </v-card-title>
                <v-row class="ma-2">
                  <v-col cols="3">
                    <v-checkbox
                      class="mt-0"
                      v-model="insertForm.fixedRateActive"
                      label="fixed rate active"
                      @change="validateForm()"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-if="insertForm.fixedRateActive"
                      class="mt-0"
                      v-model="insertForm.use_bigger_val_margin_or_fixed"
                      label="highest between margin and fixed"
                      @change="validateForm()"
                    >
                    </v-checkbox>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      v-show="insertForm.fixedRateActive"
                      type="number"
                      v-model="insertForm.fixedRate"
                      label="fixed rate"
                      dense
                      outlined
                      step="0.01"
                      prefix="£"
                      :rules="[fixedRateRule]"
                      @change="validateForm()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      v-show="
                        !insertForm.fixedRateActive ||
                        insertForm.use_bigger_val_margin_or_fixed
                      "
                      type="number"
                      v-model="insertForm.margin"
                      label="markup"
                      dense
                      outlined
                      step="1"
                      suffix="%"
                      :rules="[marginRule]"
                      @change="validateForm()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <CountriesSelect @change="selectCountry" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      type="number"
                      v-model="insertForm.weight_above"
                      label="weight above (kg)"
                      dense
                      outlined
                      step="0.01"
                      suffix="kg"
                      :rules="[weightAboveRule]"
                      @change="validateForm()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      type="number"
                      v-model="insertForm.weight_to"
                      label="weight to (kg)"
                      dense
                      outlined
                      step="0.01"
                      suffix="kg"
                      :rules="[weightToRule]"
                      @change="validateForm()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" align="center">
                    <v-btn
                      :disabled="!validForm"
                      color="primary"
                      outlined
                      @click="insertMargin(false)"
                      >Save Markup
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>

          <!-- bulk methods -->
          <v-col cols="12" v-if="selectedCarrier">
            <v-card outlined class="pa-2" :loading="loading">
              <v-card-title
                >Bulk Create/Update Markup For {{ selectedCarrier.name }}
              </v-card-title>
              <v-col cols="12">
                <ul>
                  <li>
                    if an exact match is found for the same service, country and
                    weight rules, it will update the existing markup instead of
                    creating a new rule
                  </li>
                  <li>
                    country and weight columns can be left blank (to create a
                    markup rule which applies to all countries and any package
                    weight)
                  </li>
                  <li>use the services/countries sheets as reference</li>
                  <li>make sure the correct carrier is selected</li>
                  <li>please do not change the template header names</li>
                  <li>please double check preview table before submitting</li>
                </ul>
              </v-col>
              <v-row class="pa-4">
                <v-col cols="4">
                  <v-btn color="green" outlined @click="downloadTemplate" block
                    >Download Template</v-btn
                  >
                </v-col>
                <v-col cols="4">
                  <v-file-input
                    show-size
                    counter
                    multiple
                    dense
                    outlined
                    prepend-icon=""
                    prepend-inner-icon="attach_file"
                    label="File input"
                    @change="handleFileUpload"
                  ></v-file-input>
                </v-col>
                <v-col cols="4" v-if="bulkMarginsData.length">
                  <v-btn
                    color="primary"
                    outlined
                    @click="bulkInsertMargins"
                    block
                    >Save {{ bulkMarginsData.length }} margins</v-btn
                  >
                </v-col>

                <v-col cols="12" v-if="bulkMarginsData.length">
                  <v-card outlined class="pa-2">
                    <v-card-title>Preview of bulk markup file</v-card-title>
                    <v-data-table
                      :headers="bulkMarginsHeaders"
                      :items="bulkMarginsData"
                      :items-per-page="5"
                    >
                      <template v-slot:item.company_id="{ item }">
                        <div>
                          {{ getCompanyNameFromId(item.company_id) }}
                        </div>
                      </template>
                      <template v-slot:item.carrier_id="{ item }">
                        <div>
                          {{ getCarrierNameFromId(item.carrier_id) }}
                        </div>
                      </template>
                      <template v-slot:item.carrier_service_id="{ item }">
                        <div>
                          {{
                            getcarrierServiceNameFromId(item.carrier_service_id)
                          }}
                        </div>
                      </template>
                      <template v-slot:item.fixedRateActive="{ item }">
                        <div>
                          {{ item.fixedRateActive ? "yes" : "no" }}
                        </div>
                      </template>
                      <template
                        v-slot:item.use_bigger_val_margin_or_fixed="{ item }"
                      >
                        <div>
                          {{
                            item.use_bigger_val_margin_or_fixed ? "yes" : "no"
                          }}
                        </div>
                      </template>
                      <template v-slot:item.country_code="{ item }">
                        <div>
                          {{ getCountryNameFromCode(item.country_code) }}
                        </div>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="bulkResults">
                  <v-card outlined>
                    <v-card-title>Bulk Upload Results</v-card-title>
                    <v-row class="pa-4">
                      <v-col cols="6">
                        <p><b>success messages</b></p>
                        <v-data-table
                          hide-default-header
                          :headers="[{ text: 'messages', value: 'item' }]"
                          :items="formatForTable(bulkResults.successMsgs)"
                          :items-per-page="5"
                          dense
                          class="elevation-1"
                        ></v-data-table>
                      </v-col>
                      <v-col cols="6" v-if="bulkResults.errorMsgs.length">
                        <p class="red--text"><b>error messages</b></p>
                        <v-data-table
                          hide-default-header
                          dense
                          :headers="[{ text: 'messages', value: 'item' }]"
                          :items="formatForTable(bulkResults.errorMsgs)"
                          :items-per-page="5"
                          class="elevation-1"
                        ></v-data-table>
                      </v-col>
                      <v-col cols="6" v-else>
                        <p>no errors to display</p>
                      </v-col>
                      <v-col cols="12" v-if="bulkResults.errorMsgs.length">
                        <h1>
                          <b
                            >please remove all rows that were successfuly
                            created upon retry on markups that failed</b
                          >
                        </h1>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import CompanySelect from "@/components/CompanySelectUpdated";
import Carriers from "@/services/Carriers.js";
import CountriesSelect from "@/components/partials/CountriesSelect";
import ExportButtons from "@/components/ExportButtons";
import ExcelMixin from "@/mixins/Excel";
export default {
  mixins: [ExcelMixin],
  components: { CompanySelect, CountriesSelect, ExportButtons },
  data() {
    return {
      // Title
      title: "Carrier Markup",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,
      selectedCompany: null,
      carriersWithServices: [],
      selectedCarrier: null,
      carrierServices: null,
      selectedCarrierService: null,
      search: "",

      margins: [],
      marginsHeaders: [
        { text: "Carrier Service", value: "carrierService.service" },
        { text: "markup", value: "margin" },

        { text: "is fixed rate active", value: "fixedRateActive" },
        { text: "fixed rate tariff", value: "fixedRate" },
        {
          text: "use bigger of markup or fixed",
          value: "use_bigger_val_margin_or_fixed",
        },
        { text: "country", value: "country_code" },
        {
          text: "weight above (kg)",
          value: "weight_above",
        },
        {
          text: "weight to (kg)",
          value: "weight_to",
        },

        { text: "", value: "action" },
      ],

      // updating form
      updating: false,
      updateForm: null,

      insertForm: {
        margin: null,
        fixedRate: null,
        fixedRateActive: null,
        use_bigger_val_margin_or_fixed: null,
        country_code: null,
        weight_above: null,
        weight_to: null,
      },

      globalMarginForm: {
        margin: null,
        fixedRate: null,
        fixedRateActive: null,
        use_bigger_val_margin_or_fixed: null,
      },

      validForm: false,
      currentGlobalCarrierMargin: null,
      countries: [],
      bulkMarginsData: [],
      bulkMarginsHeaders: [
        {
          text: "company",
          value: "company_id",
        },
        {
          text: "carrier",
          value: "carrier_id",
        },
        {
          text: "carrier service",
          value: "carrier_service_id",
        },
        {
          text: "markup",
          value: "margin",
        },
        {
          text: "use fixed rate",
          value: "fixedRateActive",
        },
        {
          text: "fixed rate",
          value: "fixedRate",
        },
        {
          text: "user bigger of markup or fixed",
          value: "use_bigger_val_margin_or_fixed",
        },
        {
          text: "country",
          value: "country_code",
        },
        {
          text: "weight above",
          value: "weight_above",
        },
        {
          text: "weight to",
          value: "weight_to",
        },
      ],
      bulkResults: null,
    };
  },

  created() {
    this.resetUpdatingMargin();
    this.getCarriersWithServices();
    this.getCountries();
  },
  methods: {
    formatForTable(arr) {
      return arr.map((item) => ({ item }));
    },
    downloadTemplate() {
      let sheets = [];
      let exampleService = this.selectedCarrierService
        ? this.selectedCarrierService
        : this.carrierServices[0].service;
      
      let marginsArr = [
        {
          service: exampleService,
          markup: 17.5,
          "use fixed rate": "no",
          "fixed rate": "",
          "use bigger of markup or fixed": "no",
          country: "UNITED STATES",
          "weight above": 0,
          "weight to": 20,
        },
        {
          service: exampleService,
          markup: 20,
          "use fixed rate": "yes",
          "fixed rate": 4.55,
          "use bigger of markup or fixed": "yes",
          country: "UNITED STATES",
          "weight above": 20,
          "weight to": 30,
        },
      ];

      // if margins have been loaded use that as the template instead
      if(this.margins && this.margins.length){
        marginsArr = this.margins.map( el => {
          let country = el.country_code ? this.getCountryNameFromCode(el.country_code) : ''
          let service = el.carrierService.service
            let row = {
              service: service,
              markup: el.margin,
              "use fixed rate": el.fixedRateActive ? 'yes': 'no',
              "fixed rate": el.fixedRate,
              "use bigger of markup or fixed": el.use_bigger_val_margin_or_fixed ? 'yes': 'no',
              country: country,
              "weight above": el.weight_above,
              "weight to": el.weight_to,
            }
            return row
        })
      }
       
      

      sheets.push({
        data: marginsArr,
        name: "markup",
      });

      // generate services sheet
      if (this.carrierServices.length) {
        let servicesArr = this.carrierServices.map((service) => {
          return { service: service.service };
        });
        sheets.push({
          data: servicesArr,
          name: this.selectedCarrier.name + " services",
        });
      }

      // generate countries sheet
      if (this.countries.length) {
        let mappedArr = this.countries.map((country) => {
          return { country: country.name };
        });
        sheets.push({
          data: mappedArr,
          name: "countries",
        });
      }
      let filename = "markup_for_" + this.selectedCarrier.name;
      this.generateTemplateMultiSheets(sheets, filename);
    },
    async handleFileUpload(ev) {
      const file = ev[0];
      this.file = file;

      if (file) {
        this.extractJson(file)
          .then((jsonSpreadsheet) => {
            this.bulkMarginsData = this.parseBulkMarginsData(jsonSpreadsheet);
          })
          .catch((err) => {
            this.snackbar = true;
            this.text = err;
          });
      }
    },
    parseBoolean(val) {
      if (!val) return false;
      if (typeof val === "string") {
        switch (val.toLowerCase()) {
          case "false":
          case "no":
          case "0":
            return false;
          case "true":
          case "yes":
          case "1":
            return true;
        }
      }
      return false;
    },
    matchToService(serviceName) {
      let matchedService = this.carrierServices.find((service) => {
        return service.service.toLowerCase() === serviceName.toLowerCase();
      });
      if (!matchedService)
        throw new Error(`could not map ${serviceName} to a valid service`);
      return matchedService;
    },
    matchToCountry(countryName) {
      let matchedCountry = this.countries.find((country) => {
        return country.name.toLowerCase() === countryName.toLowerCase();
      });
      if (!matchedCountry)
        throw new Error(`could not map ${countryName} to a valid country`);
      return matchedCountry;
    },
    getCountryNameFromCode(countryCode) {
      if (!countryCode) return null;
      let matchedCountry = this.countries.find((country) => {
        return country["code2"] === countryCode;
      });
      return matchedCountry.name;
    },
    getCarrierNameFromId(carrierId) {
      let matchedCarrier = this.carriersWithServices.find((carrier) => {
        return carrier.id === carrierId;
      });
      return matchedCarrier.name;
    },
    getCompanyNameFromId(companyId) {
      if (this.selectedCompany.id === companyId)
        return this.selectedCompany.name;
      return companyId;
    },
    getcarrierServiceNameFromId(serviceId) {
      let matchedService = this.carrierServices.find((service) => {
        return service.id === serviceId;
      });
      return matchedService.service;
    },

    parseBulkMarginsData(data) {
      try {
        let parsed = [];
        for (const row of data) {
          if (!row.service)
            throw new Error(
              "service column not populated, please double check the file"
            );
          let matchedService = this.matchToService(row.service);

          let country = null;
          if (row["country"]) {
            let matchedCountry = this.matchToCountry(row["country"]);
            country = matchedCountry["code2"];
          }

          let tempObj = {
            company_id: this.selectedCompany.id,
            carrier_id: this.selectedCarrier.id,
            carrier_service_id: matchedService.id,
            margin: row.markup ?? null,
            fixedRateActive: this.parseBoolean(row["use fixed rate"]),
            fixedRate: row["fixed rate"] ?? null,
            use_bigger_val_margin_or_fixed: this.parseBoolean(
              row["use bigger of markup or fixed"]
            ),
            country_code: country,
            weight_above: row["weight above"],
            weight_to: row["weight to"],
          };
          parsed.push(tempObj);
        }

        return parsed;
      } catch (err) {
        this.snackbar = true;
        this.text = err;
        return [];
      }
    },
    bulkInsertMargins() {
      this.bulkResults = null
      if (!this.bulkMarginsData.length) return;

      let reqBody = {
        auth_email: this.$auth.user.email,
        margins: this.bulkMarginsData,
      };

      Carriers.bulkInsertMargins(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.bulkResults = response.data;

          this.resetMarginsForms();
          this.getMargins();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },

    getCountryName(countryCode) {
      if (!countryCode) return countryCode;
      const countries = this.countries;
      if (!countries || !countries.length) return countryCode;
      const matchedCountry = countries.find(
        (el) => el["code2"] === countryCode
      );
      if (matchedCountry) return matchedCountry.name;
      return countryCode;
    },
    async getCountries() {
      Carriers.getCountries().then((countries) => {
        this.countries = countries;
      });
    },
    fixedRateRule(value) {
      const form = this.insertForm;
      if (form.fixedRateActive || form.use_bigger_val_margin_or_fixed) {
        if (value) {
          return true;
        } else {
          return "This field is required";
        }
      }

      return true;
    },
    marginRule(value) {
      const form = this.insertForm;
      if (!form.fixedRateActive || form.use_bigger_val_margin_or_fixed) {
        if (value) {
          return true;
        } else {
          return "This field is required";
        }
      }

      return true;
    },
    clearSelectedService() {
      this.selectedCarrierService = null;
      this.getMargins();
    },
    weightAboveRule(value) {
      const weightTo = this.insertForm.weight_to;
      if (value === 0) return true;
      if (weightTo) {
        if (parseFloat(weightTo) <= parseFloat(value)) {
          return "weight above must be lower than weight to";
        }
      }
      return true;
    },
    weightToRule(value) {
      const weightAbove = this.insertForm.weight_above;
      if (weightAbove === 0 || weightAbove) {
        if (!value) return "weight to required if weight above is set";
        if (parseFloat(value) <= parseFloat(weightAbove)) {
          return "weight to must be higher than weight above";
        }
      }

      return true;
    },
    setCarrierServices(carrier) {
      this.selectedCarrierService = null;
      this.carrierServices = carrier.carrierServices;

      this.resetFields();

      this.getMargins();
    },

    resetUpdatingMargin(item) {
      this.updating = false;
      if (item) {
        item.margin = this.updateForm.margin;
        item.fixedRate = this.updateForm.fixedRate;
        item.fixedRateActive = this.updateForm.fixedRateActive;
        item.use_bigger_val_margin_or_fixed =
          this.updateForm.use_bigger_val_margin_or_fixed;
        item.country_code = this.updateForm.country_code;
        item.weight_above = this.updateForm.weight_above;
        item.weight_to = this.updateForm.weight_to;
      }

      this.updateForm = {
        company_id: null,
        carrier_id: null,
        carrier_service_id: null,
        margin: null,
        fixedRate: null,
        fixedRateActive: false,
        use_bigger_val_margin_or_fixed: false,

        country_code: null,
        weight_above: null,
        weight_to: null,
      };
    },
    toggleUpdatingMargin(item) {
      this.updating = true;
      this.updateForm = { ...item };
    },

    getCarriersWithServices() {
      this.loading = true;
      Carriers.getCarriersWithServices()
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.carriersWithServices = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    getMargins() {
      this.margins = null;
      if (!this.selectedCompany) return;
      this.loading = true;

      this.resetUpdatingMargin();
      const companyId = this.selectedCompany.id;
      const carrierId = this.selectedCarrier ? this.selectedCarrier.id : null;

      const serviceId = this.selectedCarrierService
        ? this.selectedCarrierService.id
        : null;
      Carriers.getMargins(companyId, carrierId, serviceId)
        .then((response) => {
          this.loading = false;
          this.margins = response.data;
          if (!serviceId) {
            this.getCurrentGlobalMargin(response.data);
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    updateMargin(item) {
      this.loading = true;
      item.auth_email = this.$auth.user.email;
      Carriers.updateMargin(item)
        .then((response) => {
          this.resetUpdatingMargin();
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.getMargins();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    deleteMargin(item) {
      let confirmMsg = `Are you sure you want to delete this margin?`;
      if (confirm(confirmMsg)) {
        this.loading = true;
        let reqBody = {
          id: item.id,
          auth_email: this.$auth.user.email,
        };
        Carriers.deleteMargin(reqBody)
          .then((response) => {
            this.loading = false;
            this.snackbar = true;
            this.text = `${response.message}`;

            this.getMargins();
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = error.response
              ? error.response.data.message
              : `${error.message}`;
            this.loading = false;
          });
      }
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    insertMargin(global) {
      let valid = this.validateForm();
      if (!valid) return;

      let reqBody = this.insertForm;
      if (global) {
        reqBody = this.globalMarginForm;
      } else {
        reqBody = this.insertForm;

        if (this.selectedCarrierService) {
          reqBody.carrier_service_id = this.selectedCarrierService.id;
        }
      }
      reqBody.company_id = this.selectedCompany.id;
      reqBody.carrier_id = this.selectedCarrier.id;

      reqBody.auth_email = this.$auth.user.email;
      this.loading = true;

      Carriers.insertMargin(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.resetMarginsForms();
          this.getMargins();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },

    setCompany(company) {
      this.selectedCompany = company;
      this.resetFields();
      this.getMargins();
    },
    selectCountry(countryCode) {
      this.insertForm.country_code = countryCode;
    },
    resetMarginsForms() {
      this.insertForm = {
        margin: null,
        fixedRate: null,
        fixedRateActive: null,
        use_bigger_val_margin_or_fixed: null,
        country_code: null,
        weight_above: null,
        weight_to: null,
      };
      this.globalMarginForm = {
        margin: null,
        fixedRate: null,
        fixedRateActive: null,
        use_bigger_val_margin_or_fixed: null,
      };
      this.bulkMarginsData = [];
    },
    resetFields() {
      this.margins = [];
      this.currentGlobalCarrierMargin = null;
      this.resetMarginsForms();
    },
    getCurrentGlobalMargin(margins) {
      if (!margins || !margins.length) return;

      let globalMargin = margins.find((el) => {
        return el.carrier_id && !el.carrier_service_id;
      });
      if (globalMargin) {
        this.currentGlobalCarrierMargin = globalMargin;
        this.globalMarginForm.margin = globalMargin.margin;
        this.globalMarginForm.fixedRate = globalMargin.fixedRate;
        this.globalMarginForm.fixedRateActive = globalMargin.fixedRateActive;
        this.globalMarginForm.use_bigger_val_margin_or_fixed =
          globalMargin.use_bigger_val_margin_or_fixed;
      }
    },

    formatPercentage(value) {
      return value + "%";
    },
    formatCurrency(value) {
      return "£" + value;
    },
    formatBoolean(value) {
      return value ? "yes" : "no";
    },
  },
  computed: {
    currentMarginsHeader() {
      if (!this.selectedCarrier) return this.selectedCompany.name;
      if (!this.selectedCarrierService) return this.selectedCarrier.name;
      return this.selectedCarrierService.service;
    },
    marginsExport() {
      if (!this.margins || !this.margins.length) return [];
      return this.margins.map((row) => {
        return {
          carrier: row.carrier ? row.carrier.name : "",
          service: row.carrierService ? row.carrierService.service : "",
          margin: row.margin,
          "is fixed rate active": row.fixedRateActive ? "yes" : "no",
          "fixed rate tariff": row.fixedRate,
          "use  bigger of margin or fixed": row.use_bigger_val_margin_or_fixed
            ? "yes"
            : "no",
          country: row.country_code,
          "weight above": row.weight_above,
          weight_to: row.weight_to,
        };
      });
    },
  },
};
</script>
<style>
ul {
  list-style: circle inside none;
  padding-left: 5px !important;
}

li {
  margin-bottom: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 4px;
}
</style>
